import { TRPCClientError } from '@trpc/client'
import type { inferRouterOutputs } from '@trpc/server'
import type { AppRouter } from '@/server/trpc/routers'

type RouterOutput = inferRouterOutputs<AppRouter>
type getListOutput = RouterOutput['sites']['getList']

type ErrorOutput = TRPCClientError<AppRouter>

export default function useGetSites() {
  const { $client } = useNuxtApp()
  return useAsyncData<getListOutput, ErrorOutput>(() => $client.sites.getList.query())
}
