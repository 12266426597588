<script setup lang="ts">
import { ref } from 'vue'

const currentPage = defineProps({
  currentTab: {
    type: String,
    required: true
  }
})

const currentTabClass = 'dark:text-gray-300 text-center table-cell align-middle block border border-blue-500 rounded bg-blue-500 hover:bg-blue-700 hover:border-gray-200 text-white py-2 px-4'
const inactiveTabClass = 'dark:text-gray-300 text-center table-cell align-middle block border border-white rounded py-2 px-4 text-blue-500 dark:hover:bg-blue-700 hover:bg-gray-200'
const futureTabClass = 'dark:text-gray-300 text-center table-cell align-middle block border border-white rounded py-2 px-4 text-blue-500 dark:hover:bg-blue-700 hover:bg-gray-200'
const futureTabLink = 'cursor-not-allowed dark:text-gray-300 block text-blue-500'

const tabComparatorClass = ref('')
const tabRankingClass = ref('')
const tabSearchEngineClass = ref('')
const tabSearchEngineLinkClass = ref('')
const lightComingSoonStyle = ref('')

switch (currentPage.currentTab) {
  case 'tabComparator' :
    tabComparatorClass.value = currentTabClass
    tabRankingClass.value = inactiveTabClass
    tabSearchEngineClass.value = futureTabClass
    tabSearchEngineLinkClass.value = futureTabLink
    lightComingSoonStyle.value = 'filter: invert(1)'
    break
  case 'tabRanking' :
    tabComparatorClass.value = inactiveTabClass
    tabRankingClass.value = currentTabClass
    tabSearchEngineClass.value = futureTabClass
    tabSearchEngineLinkClass.value = futureTabLink
    lightComingSoonStyle.value = 'filter: invert(1)'
    break
  case 'tabSearchEngine' :
    tabComparatorClass.value = inactiveTabClass
    tabRankingClass.value = inactiveTabClass
    tabSearchEngineClass.value = currentTabClass
    tabSearchEngineLinkClass.value = 'cursor-not-allowed dark:text-gray-300 block text-white'
    lightComingSoonStyle.value = ''
    break
}
</script>

<template>
  <div class="mb-8">
    <!-- Version mobile -->
    <div class="flex flex-col space-y-2 border-4 dark:border-0 p-2 sm:hidden">
      <NuxtLink 
        to="/comparator"
        class="w-full px-4 py-3 text-center transition-all duration-200
        dark:border-2 dark:border-gray-700"
        :class="[
          currentTab === 'tabComparator'
            ? 'bg-blue-500 text-white dark:border-blue-500'
            : 'bg-white dark:bg-gray-800 text-blue-500 dark:text-gray-300'
        ]"
      >
        {{ $t('comparateur') }}
      </NuxtLink>

      <NuxtLink
        to="/"
        class="w-full px-4 py-3 text-center transition-all duration-200
        dark:border-2 dark:border-gray-700"
        :class="[
          currentTab === 'tabRanking'
            ? 'bg-blue-500 text-white dark:border-blue-500'
            : 'bg-white dark:bg-gray-800 text-blue-500 dark:text-gray-300'
        ]"
      >
        {{ $t('classement') }}
      </NuxtLink>

      <div 
        class="w-full px-4 py-3 text-center transition-all duration-200 relative
        bg-white dark:bg-gray-800 text-blue-500 dark:text-gray-300
        dark:border-2 dark:border-gray-700"
      >
        <span class="cursor-not-allowed">{{ $t('moteur_de_recherche') }}</span>
        <nuxt-img 
          class="absolute -top-2 -right-2 w-10 h-10 block dark:hidden" 
          src="/coming-soon-white.png" 
        />
        <nuxt-img 
          class="absolute -top-2 -right-2 w-10 h-10 hidden dark:block" 
          src="/coming-soon-black.png"
          style="filter: invert(1)" 
        />
      </div>
    </div>

    <!-- Version desktop -->
    <div class="hidden sm:flex border-4 dark:border-0">
      <NuxtLink
        to="/comparator"
        class="flex-1 px-4 py-3 text-center transition-all duration-200
        dark:border-2 dark:border-gray-700"
        :class="[
          currentTab === 'tabComparator'
            ? 'bg-blue-500 text-white dark:border-blue-500'
            : 'text-blue-500 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
        ]"
      >
        {{ $t('comparateur') }}
      </NuxtLink>

      <NuxtLink
        to="/"
        class="flex-1 px-4 py-3 text-center transition-all duration-200
        dark:border-2 dark:border-gray-700"
        :class="[
          currentTab === 'tabRanking'
            ? 'bg-blue-500 text-white dark:border-blue-500'
            : 'text-blue-500 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
        ]"
      >
        {{ $t('classement') }}
      </NuxtLink>

      <div 
        class="flex-1 px-4 py-3 text-center transition-all duration-200 relative
        text-blue-500 dark:text-gray-300
        dark:border-2 dark:border-gray-700"
      >
        <span class="cursor-not-allowed">{{ $t('moteur_de_recherche') }}</span>
        <nuxt-img 
          class="absolute -top-2 -right-2 w-10 h-10 block dark:hidden" 
          src="/coming-soon-white.png" 
        />
        <nuxt-img 
          class="absolute -top-2 -right-2 w-10 h-10 hidden dark:block" 
          src="/coming-soon-black.png"
          style="filter: invert(1)" 
        />
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
