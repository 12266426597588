import { Site } from '@prisma/client'

export const useSiteStore = defineStore('sites', () => {
  const sites: Ref<Site[]> = ref([])
  const selectedTypes: Ref<string[]> = ref([])
  const selectedDefinitions: Ref<string[]> = ref([])
  const selectedCategories: Ref<string[]> = ref([])
  const selectedOriginalTapes: Ref<string[]> = ref([])
  const selectedLanguages: Ref<string[]> = ref([])
  const isSiteListLoaded: Ref<boolean> = ref(false)

  function $reset() {
    sites.value = []
    selectedTypes.value = []
    selectedDefinitions.value = []
    selectedCategories.value = []
    selectedOriginalTapes.value = []
    selectedLanguages.value = []
    isSiteListLoaded.value = false
  }

  return {
    // states
    sites,
    selectedTypes,
    selectedDefinitions,
    selectedCategories,
    selectedLanguages,
    selectedOriginalTapes,
    isSiteListLoaded,
    // functions
    $reset
  }
}, { persist: true })
